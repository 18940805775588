import './Nav.css';
import { Link } from 'react-router-dom';
import Image from '../../components/Image/Image';

import HomeImg from '../../assets/Icons/home.svg';
import OfferImg from '../../assets/Icons/storefront.svg';
import AboutImg from '../../assets/Icons/work.svg';
import ContactImg from '../../assets/Icons/call.svg';

function Nav({ page }) {
    const links = [
        { url: '/#start', icon: HomeImg, title: 'BIKST' },
        { url: '/#oferta', icon: OfferImg, title: 'Oferta' },
        { url: '/#o-nas', icon: AboutImg, title: 'O nas' },
        { url: '/#kontakt', icon: ContactImg, title: 'Kontakt' }
    ];

    return (
        <nav className='section nav'>
            <ul className='menu'>
                {links.map((link, idx) => {
                    return <li className='menu__item' key={idx}>
                        <Link to={link.url} className='menu__link'>
                            <div className={page === link.title ? 'menu__icon menu__icon--active' : 'menu__icon'}>
                                <Image src={link.icon} type='icon' />
                            </div>
                            <p className='p'>{link.title}</p>
                        </Link>
                    </li>;
                })}
            </ul>
        </nav>
    );
}

export default Nav;
