import './NoMatch.css';
import Image from '../../components/Image/Image';
import NoMatchImg from '../../assets/Images/page-not-found.svg';

function NoMatch() {
  return (
    <section className='section no-match'>
      <h1 className='display-title no-match__title'>
        <Image src={NoMatchImg} alt='404' type='img img--no-match' />
      </h1>
      <p className='no-match__p'>Ta strona nie istnieje lub jej adres został zmieniony.</p>
      <a className='button' href='https://bikst.pl/'>Przejdź do strony głównej</a>
    </section>
  );
}

export default NoMatch;
