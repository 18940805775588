import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollTo() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (!hash) {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });
        } else {
            document.querySelector(hash).scrollIntoView();
        }
    }, [pathname, hash]);

    return null;
}

export default ScrollTo;
