import './Offer.css';
import Card from '../../components/Card/Card';
import React, { forwardRef } from 'react';

import PaintingImg from '../../assets/Images/painting-img.webp';
import TilingImg from '../../assets/Images/tiling-img.webp';
import FurnitureImg from '../../assets/Images/renovation-img.webp';

const offer = forwardRef((_, ref) => {
  const services = [
    {
      name: 'Malowanie ścian i sufitów', description: [
        'Zabezpieczenie podłóg oraz okien',
        'Odkręcenie, czyszczenie, ponowne przykręcenie włączników świateł oraz kontaktów',
        'Czyszczenie ścian, odpylanie',
        'Usuwanie pęknięć',
        'Akrylowanie w miejscach wymaganych',
        'Malowanie ścian oraz sufitów',
        'Sprzątanie po pracach malarskich',
        'Usuwanie ubytków w ścianach',
        'Usuwanie pęknięć powierzchniowych, niekonstrukcyjnych'
      ], image: PaintingImg, badge: { name: '–25%', style: '' }
    },
    {
      name: 'Mniejsze prace wykończeniowe', description: [
        'Montaż luster',
        'Montaż baterii',
        'Montaż punktów oświetlenia',
        'Montaż dekorów',
        'Inne mniejsze prace wykończeniowe'
      ], image: FurnitureImg
    },
    {
      name: 'Układanie płytek – płyt wielkoformatowych', description: [
        'Usunięcie starych okładzin',
        'Wykucie pod baterie podtynkowe',
        'Hydroizolacje',
        'Układanie płytek na ścianach oraz podłodze',
        'Fugowanie',
        'Sprzątanie po pracach'
      ], image: TilingImg
    },
  ];

  return (
    <section className='section offer' id='oferta' ref={ref}>
      <h2 className='section__headline offer__headline'>Oferta</h2>
      {services.map((service, idx) => {
        let type = idx === 0 ? 'main' : `wide`;
        return <Card key={idx} title={service.name} features={service.description} img={service.image} type={type} id={idx} badge={service.badge} />;
      })}
    </section>
  );
})

export default offer;
