import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className='footer'>
            <p className='p p--spaced'>Wszelkie prawa zastrzeżone &copy; BIKST Sp. z. o. o. 2024</p>
            <Link className='link' to='/polityka-prywatnosci'>Polityka prywatności</Link>
        </footer>
    );
}

export default Footer;
