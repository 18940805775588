import React, { useEffect, useRef } from 'react';

import Start from '../Start/Start';
import Offer from '../Offer/Offer';
import About from '../About/About';
import Contact from '../Contact/Contact';

function Home({ setPage }) {
  const startElem = useRef(null);
  const offerElem = useRef(null);
  const aboutElem = useRef(null);
  const contactElem = useRef(null);

  useEffect(() => {
    const isActive = (elem) => {
      return elem.getBoundingClientRect().top <= 300 && elem.getBoundingClientRect().bottom >= 300;
    }

    const handleScroll = () => {
      if (isActive(startElem.current)) {
        setPage('BIKST');
      } else if (isActive(offerElem.current)) {
        setPage('Oferta');
      } else if (isActive(aboutElem.current)) {
        setPage('O nas');
      } else if (isActive(contactElem.current)) {
        setPage('Kontakt');
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setPage]);

  return (
    <div className='Home'>
      <Start ref={startElem} />
      <Offer ref={offerElem} />
      <About ref={aboutElem} />
      <Contact ref={contactElem} />
    </div>
  );
}

export default Home;
