import './PrivacyPolicy.css';
import PolicySection from '../../components/PolicySection/PolicySection';
import List from '../../components/List/List';

function PrivacyPolicy() {
  return (
    <div className='privacy-policy'>
      <h1 className='section__headline'>Polityka prywatności</h1>
      <PolicySection title='Informacje ogólne'>
        <p className='subsection__p'>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników strony internetowej <a className='link link--visible' href='https://bikst.pl'>www.bikst.pl</a></p>
        <p className='subsection__p'>Wszelkie zapytania dotyczące przetwarzania danych osobowych Użytkowników wskazanej strony internetowej należy kierować na adres poczty elektronicznej: <a className='link link--visible' href='mailto:bikst.kontakt@outlook.com'>bikst.kontakt@outlook.com</a></p>
        <p className='subsection__p'>Inspektor ochrony danych osobowych nie został powołany przez Administratora danych.</p>
      </PolicySection>
      <PolicySection title='Administrator danych osobowych'>
        <p className='subsection__p'>Administratorem danych osobowych użytkowników (dalej „Użytkownicy” lub „Użytkownik”) strony internetowej <a className='link link--visible' href='https://bikst.pl'>www.bikst.pl</a> (dalej „Strona” albo „Serwis”) jest BIKST Sp. z o. o. z siedzibą w Rykach. KRS 0000978509 NIP 5060123039 REGON 522367966 (dalej „Administrator”).</p>
      </PolicySection>
      <PolicySection title='Ochrona danych osobowych'>
        <p className='subsection__p'>Administrator podejmuje wszelkie niezbędne kroki mające na cele ochronę danych osobowych Użytkownika Serwisu, zgodnie z obowiązującymi przepisami prawa. Administrator dba o bezpieczeństwo danych osobowych Użytkownika i chroni je przed nieuprawnionym dostępem ze strony osób trzecich.</p>
      </PolicySection>
      <PolicySection title='Cele, podstawy oraz metody przetwarzania danych osobowych'>
        <p className='p'>Serwis przetwarza dane użytkowników w następujących celach:</p>
        <List items={[
          'Wykorzystanie plików cookies w Serwisie – podstawę prawną stanowi art. 6 ust. 1 lit. a RODO – zgoda użytkownika.',
          'Nawiązanie kontaktu z Administratorem za pomocą poczty elektronicznej – Użytkownik dokonuje dobrowolnego przekazania danych w postaci adresu e-mail oraz treści wiadomości skierowanej do Administratora. Celem przetwarzania danych jest kontakt z Użytkownikiem – podstawę prawną stanowi art. 6 ust. 1 lit. a RODO.',
          'Zarządzanie Serwisem oraz stronami na zewnętrznych platformach m. in. Olx – podstawę prawną stanowi art. 6 ust. 1 lit. a RODO – zgoda użytkownika.'
        ]} />
      </PolicySection>
      <PolicySection title='Zakres zbieranych danych osobowych'>
        <p className='p'>Serwis zbiera następujące dane osobowe:</p>
        <List items={[
          'Informacje zbierane automatycznie – informacje o aktywności użytkownika na stronie (m. in. odwiedzane podstrony, czas wizyty), informacje o urządzeniu użytkownika (m. in. typ przeglądarki internetowej, system operacyjny)',
          'Informacje przekazywane przez użytkownika – adres e-mail, a także treść wiadomości wysłanej przez Użytkownika do Administratora mogąca zawierać inne dane osobowe takie jak np. imię i nazwisko Użytkownika'
        ]} />
      </PolicySection>
      <PolicySection title='Pliki Cookies'>
        <p className='subsection__p'>Serwis korzysta z plików cookies. Podczas pierwszej wizyty użytkownika na Stronie wyświetlany jest stosowny komunikat informujący o plikach cookies.</p>
        <p className='subsection__p'>Użytkownik ma możliwość samodzielnego dopasowania ustawień dotyczących plików cookies do własnych preferencji dzięki wykorzystaniu narzędzi udostępnianych przez przeglądarkę internetową. Korzystanie z Serwisu bez wprowadzania odpowiednich zmian w ustawieniach przeglądarki jest równoznaczne z wyrażeniem zgody na pliki cookies.</p>
        <p className='subsection__p'>Cookies są to dane zapisane w postaci niewielkich plików tekstowych przechowywanych na urządzeniu Użytkownika. Pozwalają na identyfikację przedlądarki wykorzystywanej przez Użytkownika do odwiedzenia Serwisu, a także monitorowania i analizowania ruchu Użytkownika na Stronie. Dzięki temu możliwe jest stałe ulepszanie Serwisu.</p>
        <p className='subsection__p'>W Serwisie wykorzystywane są dwa rodzaje plików cookies: trwałe oraz sesyjne. Cookies trwałe pozostają na urządzeniu Użytkownika do momentu ich ręcznego usunięcia, a natomiast cookies sesyjne są przechowywane jedynie w ramach pojedynczej sesji, a następnie są automatycznie usuwane przez przeglądarkę internetową.</p>
        <p className='subsection__p'>Szczegółowe informacje dotyczące działania oraz sposobu zarządzania plikami cookies są dostępne na stronie: <a className='link link--visible' href='https://wszystkoociasteczkach.pl/'>https://wszystkoociasteczkach.pl/</a></p>
        <p className='subsection__p'>Użytkownik może zablokować pliki Cookie wykorzystując ustawienia posiadanego oprogramowania tj. przeglądarki internetowej. Użytkownik może ponadto usunąć pliki cookies. Aby przeprowadzić tą operację konieczne jest skorzystanie z odpowiednich opcji udostępnianych przez przeglądarkę internetową. Opisane działania mogą prowadzić do ograniczenia funkcjonalności lub niepoprawnego działania Serwisu.</p>
      </PolicySection>
      <PolicySection title='Udostępnianie danych osobowych'>
        <p className='p'>W celu prowadzenia serwisu administrator korzysta z usług świadczonych przez podmioty zewnętrzne. Skutkuje to możliwością wystąpienia udostępnienia danych Użytkownika następującym podmiotom:</p>
        <List items={[
          'dostawca domeny – OVH Sp. z o. o. (ul. Swobodna 1, 50-088 Wrocław; NIP: 899-25-20-556)',
          'dostawca usług hostingowych –	Cloudflare, Inc. (101 Townsend St., San Francisco, California 94107, USA)',
          'dostawca map –	Google LLC. (1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA)',
          'podmioty analizujące aktywność Użytkownika: a.	Google LLC. (1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA), b.	Cloudflare, Inc. (101 Townsend St., San Francisco, California 94107, USA)',
          'podmiot obsługujący wysyłkę i odbiór poczty elektronicznej – Microsoft Corporation (920 Fourth Avenue, Suite 2900, Seattle, Washington 95104)'
        ]} />
        <p className='subsection__p'>Warunki, cele, sposób przetwarzania oraz zakres gromadzonych danych zostały opisane w politykach prywatności poszczególnych podmiotów zewnętrznych.</p>
      </PolicySection>
      <PolicySection title='Okres przechowywania danych osobowych'>
        <p className='subsection__p'>Dane osobowe Użytkownika są przechowywane przez okres wynikający z konieczności spełnienia obowiązku prawnego, a także usług świadczonych na rzecz Użytkownika.</p>
        <p className='subsection__p'>Administrator zastrzega sobie prawo do przetwarzania danych osobowych Użytkownika w sytuacji cofnięcia zgody, jeżeli jest do tego zobligowany z tytułu obowiązującego prawa albo w przypadku wystąpienia konieczności dochodzenia roszczeń przed sądem.</p>
      </PolicySection>
      <PolicySection title='Przekazywanie danych do państw spoza EOG'>
        <p className='subsection__p'>Dane Użytkownika Serwisu nie są przekazywane przez Administratora do państw spoza Europejskiego Obszaru Gospodarczego. Jednakże podmioty zewnętrzne, z których usług korzysta Administrator w określonych sytuacjach mogą przekazywać dane osobowe Użytkownika do państw spoza EOG.</p>
      </PolicySection>
      <PolicySection title='Prawa użytkownika'>
        <p className='p'>Użytkownikowi przysługują następujące prawa:</p>
        <List items={[
          'Prawo do dostępu do danych osobowych',
          'Prawo do sprostowania/korekty danych osobowych',
          'Prawo do usunięcia danych osobowych (jedynie, gdy nie ma podstaw do ich dalszego przetwarzania)',
          'Prawo do ograniczenia przetwarzania danych osobowych (jedynie, gdy nie ma podstaw do ich dalszego przetwarzania)',
          'Prawo do wniesienia sprzeciwu co do przetwarzania danych osobowych',
          'Prawo do odwołania zgody na przetwarzanie danych osobowych (jeżeli przetwarzanie danych osobowych dokonywane jest na jej podstawie) w dowolny momencie. Odwołanie wskazanej zgody nie wpływa na zgodność z prawem przetwarzania danych, które prowadzono na podstawie zgody przed jej odwołaniem.',
          'Prawo do przenoszenia danych osobowych.',
          'Prawo do wniesienia skargi do Prezesa UODO, jeżeli uważa, że istnieją ku temu podstawy.'
        ]} />
      </PolicySection>
      <PolicySection title='Automatyczne podejmowanie decyzji'>
        <p className='subsection__p'>Administrator może prowadzić ograniczone działania noszące znamiona automatycznego przetwarzania danych osobowych Użytkowników w ramach realizacji jego prawnie uzasadnionego interesu. Działania te mają jedynie charakter marketingowy i nie wiążą się z nimi prawne skutki wobec Użytkownika.</p>
      </PolicySection>
      <PolicySection title='Postanowienia końcowe'>
        <p className='subsection__p'>Administrator zastrzega sobie prawo do wprowadzania zmian w polityce prywatności Serwisu, które mogą być wywołane rozwojem technologii informatycznych, zmianami w obowiązującym prawie, rozbudową lub ograniczeniem funkcjonalności Serwisu.</p>
        <p className='subsection__p'>Użytkownik korzystając z Serwisu powinien uprzednio zapoznać się z aktualną wersją polityki prywatności. Polityka prywatności wchodzi w życie z dniem 13.07.2022 roku i obowiązuje od tej daty.</p>
      </PolicySection>
    </div>
  );
}

export default PrivacyPolicy;