import Image from '../../components/Image/Image';
import React, { forwardRef } from 'react';
import AboutImg from '../../assets/Images/about-img.webp';

const about = forwardRef((_, ref) => {
  return (
    <section className='section about' id='o-nas' ref={ref}>
      <h2 className='section__headline about__headline'>O nas</h2>
      <Image src={AboutImg} type='img img--about' />
      <section className='subsection about__p1'>
        <h3 className='subsection__headline'>Kim jesteśmy?</h3>
        <p className='p'>Jesteśmy firmą stawiającą na jakość wykonywanych usług oraz dobry kontakt z klientem. Posiadamy doświadczenie w pracy z różnymi produktami zarówno z niższej półki cenowej, jak i wysokiej jakości produktami renomowanych marek premium. Zawsze staramy się pomóc klientowi.</p>
      </section>
      <section className='subsection about__p2'>
        <h3 className='subsection__headline'>Wartości</h3>
        <p className='p'>Pracujemy na terenie województwa lubelskiego. Okazjonalnie przyjmujemy również zlecenie z Warszawy i okolic.</p>
      </section>
    </section>
  );
});

export default about;
